import React, { useState } from 'react';
import HeaderImageSection from './../Home-sub-components/subComponent';
import Footer from '../Footer';
import './index.css';
import contactBgImg from './../../assets/images/contact_bg.png';
import ContactAddress from './Addess-page';

const Contact = () => {
    const [contactPage] = useState('contactPage');
    const [title] = useState('IMMERSE YOUR PRACTICE TO REVENUE GROWTH AND STRENGTHEN THE PRACTICE');

    return(
        <div  id="contact-page">
            <HeaderImageSection
                page={contactPage}
                title = {title}
                backgroundImg = {contactBgImg}
            />
            <div className="mt-20">
                <ContactAddress />
            </div>
            <Footer/>
        </div>
    )
}

export default Contact;
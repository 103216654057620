import React from 'react';
import { useLocation } from 'react-router-dom';


const Navbar = () => {

    const location = useLocation();

    const isActive = (path) => {
        return location.pathname === path ? 'active' : '';
    }

    return (
        <div className="row">
            <div className="col-lg-10 col-xl-10 mx-auto ">
                <nav className="navbar navbar-expand-lg navbar_bgcolor">
                    <div className="container-fluid">
                        {/* <a className="navbar-brand" href="#">Navbar</a> */}
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                            <div className="navbar-nav">
                                <a className={`nav-link py-3 ${isActive('/')}`}  href="/">Home</a>
                                <a className={`nav-link py-3 ${isActive('/atglance')}`} href="/atglance">AT GLANCE</a>
                                <a className={`nav-link py-3 ${isActive('/our-service')}`} href="/our-service">OUR SERVICES</a>
                                <a className={`nav-link py-3 ${isActive('/about')}`} href="/about">ABOUT US</a>
                                <a className={`nav-link py-3 ${isActive('/contact')}`} href="/contact">CONTACT US</a>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </div>

    )
}

export default Navbar;
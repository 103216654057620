import React from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import About from './components/About';
import AtGlance from './components/At-glance';
import Contact from './components/Contact';
import Home from './components/Home';
import OurService from './components/Our-service';


const Router = () => {
    return (
      <BrowserRouter>
          <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/atglance" element={<AtGlance />} />
              <Route path="/our-service" element={<OurService />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/about" element={<About />} />
          </Routes>
      </BrowserRouter>
    );
  }
  
  export default Router;
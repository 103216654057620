import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';

const SideNavbar = () => {

    const location = useLocation();

    const isActive = (path) => {
        return location.pathname === path ? 'active': '';
    }

    return (
        <div id="sideNav">
            <button className="btn btn-primary sidenavbar_menu_btn" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasLeft" aria-controls="offcanvasLeft"><FontAwesomeIcon icon={faBars} /></button>
            
            <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasLeft" aria-labelledby="offcanvasLeftLabel">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasLeftLabel">PhyMedsol</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                <div>
                            <div className="navbar-nav mx-auto">
                                <a className={`nav-link px-3 ${isActive('/')}`} href="/">Home</a>
                                <a  className={`nav-link px-3 ${isActive('/atglance')}`}  href="/atglance">AT GLANCE</a>
                                <a  className={`nav-link px-3 ${isActive('/our-service')}`}  href="/our-service">OUR SERVICES</a>
                                <a  className={`nav-link px-3 ${isActive('/about')}`}  href="/about">ABOUT US</a>
                                <a  className={`nav-link px-3 ${isActive('/contact')}`}  href="/contact">CONTACT US</a>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    )
}

export default SideNavbar;

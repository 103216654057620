import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './index.css';

import medicalCoding from './../../assets/images/medical-coding.png';
import EligibiltyImg from './../../assets/images/eligibilty.png';
import codingWorkImg from './../../assets/images/coding_work.png';
import accountReceivableImg from './../../assets/images/account_rece.png';
import BillingServiceImg from './../../assets/images/billing_service.png';
import CreditBalanceImg from './../../assets/images/credit_balance.png';

const HomeOurService = () => {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 5000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 4000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const data = [
        {
            img: codingWorkImg,
            title: 'HIPPA COMPLIANCE',
            description: 'Implementing steps in your process can enhance your HIPAA compliance and help you effectively mitigate potential risks associated with non-compliance.',
            url:'./our-service#hippa-compliance',
        },
        {
            img: medicalCoding,
            title: 'MEDICAL CODING',
            description: 'Medical Coding is the transformative system that has brought standardization to the healthcare industry. Each procedure, diagnosis, and services..',
            url:'./our-service#medical-coding',
        },
        {
            img: EligibiltyImg,
            title: 'PATIENT ELIGIBILITY VERIFICATION',
            description: 'We recommend clients always opt for verification & eligibility to increase revenue at first stage. To Initiate, we obtain patient data from EMR/PMS..',
            url: './our-service#patient-eligibilty',
        },
        {
            img: codingWorkImg,
            title: '24/7 CODING WORKFLOW',
            description: 'Medical coding is incredibly tough in the post-reform environment. It does not begin and end with assigning appropriate codes to medical services..',
            url: './our-service#coding-workflow',
        },
        {
            img: BillingServiceImg,
            title: 'BILLING SERVICE',
            description: 'Medical Billing has become the crucial part of revenue management for the healthcare service provider and hospitals. Millions of dollars are lost annually..',
            url:'/our-service#billing-service',
        },
        {
            img: accountReceivableImg,
            title: 'ACCOUNTS RECEIVABLE',
            description: 'Many healthcare providers have tried managing accounts receivables, in the process ended up mismanaging the whole thing, resulting in reduced cash flow..',
            url:'./our-service#account-receivable',
        },
        {
            img: CreditBalanceImg ,
            title: 'CREDIT BALANCE RESOLUTION',
            description: 'Federal payers are regulated by law on overpayments; any delay in reimbursement will certainly attract fines and multiple instances of unresolved credit balances..',
            url:'./our-service#credit-balance',
        }
    ]

    const handleReadMoreClick = (event, url) => {
        event.preventDefault();
        window.location.href = url;
    };

    return (
        <section id="ourServiceCarousel">
            <div className="container">
                <div className="row">
                    <div className="col-lg-11 col-xl-11 col-12 mx-auto">
                        <div className="header_title">OUR SERVICES</div>
                        <div className="sub_heading">A complete Revenue Cycle Management partner for your practice.</div>
                        <Carousel
                            draggable={false}
                            showDots={true}
                            responsive={responsive}
                            infinite={true}
                            autoPlaySpeed={10000}
                            keyBoardControl={true}
                            // customTransition="all .5"
                            transitionDuration={500}
                            containerClass="carousel-container"
                            removeArrowOnDeviceType={["tablet", "mobile"]}
                            dotListClass="custom-dot-list-style"
                            itemClass="carousel-item-padding-300-px"
                            autoPlay

                        >
                            {
                                data?.map((item, index) => {
                                    return (
                                        <div className="mr-4 carousel_section" key={index}>
                                            <div className="mr-4">
                                                <img src={item.img} className="img-fluid mr-3 border_radius_25" />
                                            </div>
                                            <div className="description">
                                                <div className="text-center font-size-16-600">{item.title}</div>
                                                <div className="description_text">{item.description}</div>
                                                <div className="text-center">
                                                   <a href={item.url} className="btn btn-primary btn-round" onClick={(event) => handleReadMoreClick(event, item.url)}>READ MORE</a>
                                                </div>
                                            </div>

                                        </div>
                                    )
                                })
                            }

                        </Carousel>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HomeOurService;
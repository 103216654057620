import React from 'react';
import './index.css';
import serviceImage from './../../assets/images/Doctor.png';
import EligibiltyCheckIcon from './../../assets/images/glance/charge-entry.svg';
import MedicalCodingIcon from './../../assets/images/glance/medical_icon.svg';
import ChargeEntryIcon from './../../assets/images/glance/charge-entry.svg';
import ClaimsIcon from './../../assets/images/glance/claims_icon.svg';
import ElectronicIcon from './../../assets/images/glance/electronic_icon.svg';
import PaymentIcon from './../../assets/images/glance/payment_icon.svg';
import FollowupIcon from './../../assets/images/glance/followup_icon.svg';
import PatientIcon from './../../assets/images/glance/patient_icon.svg';
// import serviceImage from './../../assets/images/at-glance/service-glance.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCircleCheck, faDollarSign, faWineBottle } from '@fortawesome/free-solid-svg-icons';

const ServiceComponent = () => {

    const first_section = [
        {
            title: 'ELIGIBILITY CHECK',
            description: 'We affirm each Patients Insurance coverage eligibility to streamline your technique, shorten account receivable days, and keep away from denials.',
            image: EligibiltyCheckIcon,
        },
        {
            title: 'MEDICAL CODING',
            description: 'Effective medical coding requires comprehensive decision making, pin-point accuracy, and proven expertise. Our certified medical coding experts, code auditors and quality analysts guarantee coding accuracy. We keep our coders up to date with frequently changing CPT, ICD-10, HCPCS books & softwares.',
            image: MedicalCodingIcon
        },
        {
            title: 'CHARGE ENTRY',
            description: 'Get well-timed compensation as our targeted and error-loose charge access process ensures first-time accredited declaration submission.',
            image: ChargeEntryIcon
        },
        {
            title: 'CLAIMS SCRUBBING',
            description: 'Our one of the best claim scrubbing ensures error-free and smooth claims are submitted for quicker reimbursement and minimum denials/rejections.',
            image: ClaimsIcon
        },
    ]

    const second_section = [
        {
            title: 'ELECTRONIC SUBMISSION',
            description: 'Corrections and re-submissions are completed systematically and in a timely manner for any claims that are held to identify and resolve the issue as soon as possible.',
            image: ElectronicIcon,
        },
        {
            title: 'PAYMENT POSTING',
            description: 'Our Team follow payment posting essential steps such as posting, deposit functions and reconciling posting activities. This provides overview of your entire economic cycle, including the revenue leaks, so that you can sort the issue promptly.',
            image: PaymentIcon,
        },
        {
            title: 'FOLLOW-UP & APPEALS',
            description: 'For any denial, our A/R specialists will take rapid and powerful action to ensure well-timed reimbursements, preserving you up-to-date on appeals and reprocessing.',
            image: FollowupIcon,
        },
        {
            title: 'PATIENT STATEMENTS',
            description: 'Generation of detailed patient statements providing detailed explanation of charges, co-insurance and out-of-pocket info showcasing patient’s responsibility...',
            image: PatientIcon,
        },
    ]

    return (
        <section id="service_section">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-10 mx-auto">
                        <div className="serive_header_title text-center">SERVICES AT GLANCE</div>
                        <div className="row">
                            <div className="col-xl-4 col-lg-4 col-12 mt-3">
                                {
                                    first_section?.map((item, index) => {
                                        return (
                                            <div className="row" key={index}>
                                                <div className="col-lg-9 col-md-9 col-9 mt-4">
                                                <div className="service_section1_title">{item.title}</div>
                                                    <div className="service_section1_description">{item.description}</div>
                                                </div>
                                                <div className="col-lg-3 col-md-3 col-3">
                                                <div className="img_line_section_left">
                                                     {/* <i className={`first_section_icon ${item.icon}`} ></i> */}
                                                     <img src={item.image} className="img-fluid side-img" />
                                                     <div className={index !== 3 ? 'bottom_line' : ''}></div>
                                                 </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="col-xl-4 col-lg-4 col-12 mx-auto mt-3 text-center">
                                <div>
                                    <img src={serviceImage} alt="doctor image" className="img-fluid" />
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-12 text-center mt-3">
                            {
                                    second_section?.map((item, index) => {
                                        return (
                                            <div className="row" key={index}>
                                                 <div className="col-lg-3 col-md-3 col-3">
                                                <div className="img_line_section_right">
                                                     {/* <i className={`first_section_icon ${item.icon}`} ></i> */}
                                                     {/* <FontAwesomeIcon className="first_section_icon" icon={item.icon} /> */}
                                                     <img src={item.image} className="img-fluid side-img"/>
                                                        <div className={index !== 3 ? 'bottom_line' : ''}></div>
                                                 </div>
                                                </div>
                                                <div className="col-lg-9 col-md-9 col-9 mt-4">
                                                <div className="service_section2_title">{item.title}</div>
                                                    <div className="service_section1_description">{item.description}</div>
                                                </div>
                                               
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceComponent;
import React, { useState } from 'react';
import './sub-component.css';
import Navbar from './navbar';
import HeaderImageSection from './header-image-section';
import HeaderCarousel from './home-carousel';
import SideNavbar from './sideNavbar';
// import Logo from './../../assets/images/logo-white.png';
import Logo from './../../assets/images/logo.png';

const HomePageSubComponents = (props) => {
    
    return (
        <div id="header" >
            <section className="header_bg">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="text-white logo">
                                <img src={Logo} alt="logo" className="logo-img" />
                            </div>
                            <SideNavbar />
                            <div className="row">
                                <div className="col-md-12 mx-auto">
                                    <Navbar />
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-xl-10 col-lg-10 col-md-10 col-12 mx-auto">
                                   <HeaderImageSection {...props} />
                                </div>
                            </div>
                            {
                                (props.page !== 'contactPage' && props.page !== 'atGlancePage' && props.page !== 'ourServicePage') && <div className="row">
                                <div className="col-lg-7 mx-auto z-index-999" >
                                        <HeaderCarousel {...props} />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default HomePageSubComponents;
import React from "react";
import  './index.css';
// import logo from './../../assets/images/logo-white.png';
import logo from './../../assets/images/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';

  

const Footer = () => {
    return (
        <section id="footer-page">
            <div className="container">
               <div className="row">
               <div className="col-xg-11 col-lg-11 col-12 mx-auto">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-12 mb-5 border_right_line">
                            <div className="text-center">
                                <img src={logo} className="img-fluid footer_logo" />
                            </div>
                            <div className="text-center mt-5 mb-5">
                                <span className="twitter_rigt_margin"> <FontAwesomeIcon className="first_section_icon" icon={faLinkedin} /></span>
                                <span className="facebook_rigt_margin"> <FontAwesomeIcon className="first_section_icon mr-3" icon={faFacebookF} /></span>
                                <span className="insta_rigt_margin"> <FontAwesomeIcon className="first_section_icon mr-3" icon={faInstagram} /></span>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-12 border_right_line mb-5 text-center">
                            <div className="presence_text mb-3 mt-4">OUR PRESENCE</div>
                            <div className="footer_presence_text">
                                <div className="city_name">North America</div>
                                274 Merry Brook Circle, <br/>
                                Sanford, FL 32771, USA. 
                            </div>
                            <div className="footer_presence_text">
                                <span>Phone:</span> <a href="callto:(689)2244176">+1 (689) 224-4176</a>
                            </div>
                            <div className="footer_presence_text">
                                <a href="mailto:info@phymedsol.com">info@phymedsol.com</a>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-12 mt-5 text-center">
                        <div className="footer_presence_text mt-4">
                            <div className="city_name">Asia Pacific</div>
                                Nancy Buliding <br/>
                                Main road, Hi-Tech City <br/>
                                Hyderabad, TS 500085. 
                            </div>
                            <div className="footer_presence_text">
                                <span>Phone:</span> <a href="callto:9963611612">+91 9963611612</a>
                            </div>
                            <div className="footer_presence_text">
                                <a href="mailto:info@phymedsol.com">info@phymedsol.com</a>
                            </div>
                        </div>
                    </div>
                </div>
               </div>
            </div>
        </section>
    )
}

export default Footer;
import React, { useState, useRef, useEffect } from 'react';
import HeaderImageSection from './../Home-sub-components/subComponent';
import Footer from '../Footer';
import './index.css';
import OurServiceBgImg from './../../assets/images/our-service/header_img.png';
import thirdImage from './../../assets/images/our-service/bg_img.jpg';


const OurService = () => {
    const [ourServicePage] = useState('ourServicePage');
    const [title] = useState('IMMERSE YOUR PRACTICE TO REVENUE GROWTH AND STRENGTHEN THE PRACTICE');

    const hippaComplainceRef = useRef(null);
    const medicalCodingRef = useRef(null);
    const eligibilityRef = useRef(null);
    const billingServiceRef = useRef(null);
    const accountReceivableRef = useRef(null);
    const codingWorkRef = useRef(null);
    const CreditBalanceRef = useRef(null);

    useEffect(() => {
        const { hash } = window.location;
        if (hash === '#hippa-compliance' && hippaComplainceRef.current) {
            hippaComplainceRef.current.scrollIntoView({ behavior: 'smooth' });
          } else if (hash === '#medical-coding' && medicalCodingRef.current) {
          medicalCodingRef.current.scrollIntoView({ behavior: 'smooth' });
        } else if (hash === '#patient-eligibilty' && eligibilityRef.current) {
          eligibilityRef.current.scrollIntoView({ behavior: 'smooth' });
        } else if (hash === '#billing-service' && billingServiceRef.current) {
          billingServiceRef.current.scrollIntoView({ behavior: 'smooth' });
        }  else if (hash === '#coding-workflow' && billingServiceRef.current) {
            codingWorkRef.current.scrollIntoView({ behavior: 'smooth' }); 
        } else if (hash === '#account-receivable' && billingServiceRef.current) {
            accountReceivableRef.current.scrollIntoView({ behavior: 'smooth' }); 
        }  else if (hash === '#credit-balance' && billingServiceRef.current) {
            CreditBalanceRef.current.scrollIntoView({ behavior: 'smooth' }); 
        }
        // Add more conditions for other sections if needed
      }, []);

    return (
        <div id="ourservice-page">
            <HeaderImageSection
                page={ourServicePage}
                title={title}
                backgroundImg={OurServiceBgImg}
            />
            <div className="service_heading text-center mt-5">Our Services</div>
            <div className="heading_border mb-3"></div>
            <div className="text-center service_sub_heading">A complete Revenue Cycle Management partner for your practice. </div>
            <div className="container">
                <div className="row">
                    <div className="col-9 mt-5 mx-auto">
                        <div className="sub_heading" id="patient-eligibilty" ref={eligibilityRef}>Patient Eligibility Verification</div>
                        <div className="para mt-3 mb-3">We recommend clients always opt for verification & eligibility to increase revenue at first stage. To Initiate, we obtain patient data from EMR/PMS or retrieve data through FTP, secure HIPAA compliance email, fax etc.</div>
                        <div className="sub_heading mb-2">Check list:</div>
                        <div className="para mb-3"> Health insurance carrier status, type of plan, group numbers, dependent coverage under plan, service covered or not covered, deductibles, co-pay, co-insurance, referrals, prior auth/ retro auth, plan limitations or frequency limit, COB.</div>
                        <div className="heading text-center mb-2" id="hippa-compliance" ref={hippaComplainceRef}>HIPAA compliance</div>
                        <div className="para mb-3">Implementing steps in your process can enhance your HIPAA compliance and help you effectively mitigate potential risks associated with non-compliance.</div>
                        <div className="para mb-3">To help you get started few steps are listed below:</div>
                        <div className="para mb-3">Set up security policies and procedures: Implement cohesive HIPAA compliance policies to reduce errors in day-to-day activities that cover all aspects of handling the PHI. These policies should be regularly reviewed and updated to meet the regulatory requirements.
</div>
<div className="para mb-3">Implement Internal Audits: Perform regular risk assessments and internal audits to ensure continuous HIPAA compliance and to evaluate the likelihood of potential vulnerabilities and threats.</div>
<div className="para mb-3">Establish and maintain protocols: Train healthcare associates and employees’ third-party vendors on HIPAA-related security protocols and document the function of employees, their desired roles, and the extent of their PHI use.</div>
                        <div className="heading text-center mb-4 mt-5" id="medical-coding" ref={medicalCodingRef}>Medical Coding and how it impacts your Revenue</div>
                        <div className="para mb-4"> Medical Coding is the transformative system that has brought standardization to the healthcare industry. Each procedure, diagnosis, and services have a code assigned to it, which are then being used for filing claims to Insurance companies. If not being billed with compliance driven process and any errors may affect the RCM Workflow.
                            Medical coding is incredibly tough in the post-reform environment. It doesn't begin and end with assigning appropriate codes to medical services, like match the following exercises in kindergarten. You need to work with experienced and credentialed coders who know their way around your EHR, to maximize revenue. You need to work with PhyMedSol, the experts you need to help maximize your revenue.</div>
                        <div className="sub_heading mb-3">What are your top challenges?</div>
                        <div className="para_ul">
                            <ul>
                                <li>Creating and submitting clean claims on time</li>
                                <li>Staying compliant with federal regulations</li>
                                <li>Coder retention and vacancy bottlenecks</li>
                                <li>Managing overheads</li>
                            </ul>
                        </div>
                        <div className="sub_heading mb-3" id="coding-workflow" ref={codingWorkRef}>98% coding accuracy, 24/7 coding workflow and a lot more</div>
                        <div className="para_ul">
                            <ul>
                                <li>Exceptional medical coding, abstracting and code auditing services</li>
                                <li>AAPC/ CPC, AHIMA/CCS credentialed coding experts</li>
                                <li>Specialty focused coding support- Certified coders for each specialty</li>
                                <li>Conversant in working with Clinical Information Systems</li>
                                <li>Expertise in coding for professional claims and facility claims</li>
                                <li>Extensive NCCI and MUE edits</li>
                            </ul>
                        </div>
                        <div className="para mb-2"> We use the most up to date books and software like CPT, ICD-9, HCPCS, to keep in touch with the constant changes in the coding world.</div>
                        <div className="para_ul">
                            <ul>
                                <li>We follow HIPAA regulations in order to assure turn around time</li>
                                <li>Our dedicated professionals work under the AHIMA rules</li>
                                <li>Our coding staff has received extensive training under coding experts in the US</li>
                                <li>Certified coders monitor their work regularly and external coding and compliance experts periodically audit the department.</li>
                                <li>The coder determines the code to be used following each patient encounter. An AAPC Coder is extremely careful while coding for patient records. Utmost attention is given to quality to get reimbursement from the Insurance Company for the services rendered by the physician</li>

                            </ul>
                        </div>
                        <div className="heading text-center mb-4 mt-5" id="billing-service" ref={billingServiceRef}>Billing Service</div>
                        <div className="para mb-4">Medical Billing has become the crucial part of revenue management for the healthcare service provider and hospitals. Millions of dollars are lost annually due to underpricing, coding errors, missed charges and un-reimbursed claims. PhymedSol’s medical billing service enables you to eliminate these losses and accurately process the medical billing. Our professional approach and expertise always help our client by freeing with the tedious tasks of billing and follow-up function. PhymedSol’s medical billing service incorporates the latest technological and software developments and is very user-friendly. Medical Billing service is customized to meet individual clients specific requirements.</div>
                        <div className="sub_heading mb-3">Medical Billing Service offers you</div>
                        <div className="para_ul">
                            <ul>
                                <li>Data entry - we obtain information from the provider and/or practice and enter this information into our database.</li>
                                <li>File claims - most claims are filed electronically and only a few go on paper. This is dependent upon the insurance carrier the claims are going to.</li>
                                <li>Statements - are mailed out from our office on behalf of the facility. The protocols of statements and collections are done specifically to client's specifications.</li>
                                <li>Payments - as we receive payments and explanations of benefits from the client, we post these into our database. Each EOB will be audited for correct payment and/or benefits.</li>
                                <li>Reports are done on a monthly basis. Because each provider and/or practice is different, we are always open to customizing reports according to client's requirements.</li>

                            </ul>
                        </div>
                        <div className="sub_heading mb-3">Demographic and charge entry service</div>
                        <div className="para mb-3">We have expertise in Demographic and Charge entry service for many specialties ranging from General Practice to more complicated specialties. We provide demographic identification and verification as patients register for healthcare services.</div>
                        <div className="para_ul">
                            <ul>
                                <li>Patient Demographic Entry Services Individual patient details are cataloged in billing software. The entries consist of Patient Demographics, Insurance, Employment and sponsor details.</li>
                                <li>Patient Demographic Update Services This service consists of verifying patient information, registered in the billing software with the current information and update if necessary.</li>
                                <li>Charge Entry Services Our charge entry service consists of entering CPT codes, ICD codes, modifiers and other related information into the Billing Software</li>
                            </ul>
                        </div>
                        <div className="sub_heading mb-3">Claim submission</div>
                        <div className="para mb-3">The claims submission to the insurance company proceeds electronically after demographics and charges are entered into the billing software. Speeding up the reimbursement and reducing the claim rejection.</div>
                        <div className="para_ul">
                            <ul>
                                <li>Reimbursement of your claims.</li>
                                <li>Collection on your aged accounts receivable.</li>
                                <li>Electronic processing of medical claims.</li>
                                <li>Significant reduction in your practice cost and dramatic improvement of your cash flow.</li>
                                <li>Guaranteed satisfaction.</li>
                            </ul>
                        </div>
                        <div className="sub_heading mb-3">Payment posting service</div>
                        <div className="para mb-3">Professional Cash posting Service delivers account creation and payment posting service for each individual patient record. Recording and managing of cash posting services require expert and dedicated human skills and innovative technology. Our highly skilled and experienced executives do Insurance payment posting, Self-pay posting and Denial posting, maintaining high levels of accuracy and confidentiality.</div>
                        <div className="para mb-3">Our success in cash posting service has been driven through the successful assimilation of technology and customer focus. We have constantly invested in technology and experienced manpower.</div>
                        <div className="para_ul">
                            <ul>
                                <li>Insurance payment posting - Payment posting service consists of posting primary insurance payments, adjustments and transfer co-insurance to secondary insurance (if available) or patient. Posting of secondary insurance payment is also done by us.</li>
                                <li>Self-pay posting - Self-pay posting service consists of posting payments made by patients to the healthcare provider. Denial posting. Claims needing resubmission that are claims denied by insurance are checked for all necessary documents like Medical records, Referral, Authorization etc. and resubmitted.</li>
                                <li>Quality process - Our quality assurance team reviews cash posting transactions. Checks are done to validate the fields such as check number, co-insurance transfer, and adjustment. Denial and re-submission of claims posting service are very important as it involves a specific time period within which claim has to be re-submitted. Our quality team assures that all denial and re-submission of claims posting is done within time and without missing any record including all supporting documents and information.</li>
                                <li>Reports - The reports regarding transaction volume (Number of line items received and submitted by us) are provided to clients as part of the service. These reports are generated in Daily, Weekly/Fortnightly, Monthly based on the customer's preference.</li>

                            </ul>
                        </div>
                        <div className="para mb-3">In addition to processing your claims, our staff will be alert at spotting any problems in your office with regards to proper preparation and delivery of billing information. This extra care assures you that no matter how hectic your office becomes there is someone watching the financial end of your practice.
                            With rapidly changing medical billing needs and regulations, our staff provides security and confidence that, at all times your financial interests are being given the full attention they deserve, so that you can focus on what you do best i.e. providing quality healthcare. PhyMedSol strives to develop long-term relationships by earning trust through dedication and results. We hope that we have the opportunity to provide our medical billing service to you.</div>
                        <div className="heading text-center mb-4 mt-5" id="account-receivable" ref={accountReceivableRef}>Accounts Receivable</div>
                        <div className="para mb-4">Many healthcare providers have tried managing accounts receivables, in the process ended up mismanaging the whole thing, resulting in reduced cash flow and increased collection cost and negligence. Our account receivable service improves cash flow, reduces account receivables, increases collection ratios, and enhances customer relationships with timely AR Follow-Up Service. PMS has invested in building cutting-edge technology and a highly skilled workforce to provide best practice and service. Our professional approach and timely follow-up have increased the revenue to many healthcare providers. Call center executives meticulously follow patient records, analyze them, take up with the insurance companies, address and rectify the problem.</div>
                        <div className="para_ul">
                            <ul>
                                <li>Receivable Analysis</li>
                                <li>Payer Follow-up</li>
                                <li>Denials Management Reporting</li>
                                <li>Practice Analysis</li>
                                <li>Assessment Process: Daily Insurance aging report is run and claims are analyzed. The analysis is done to identify:</li>
                                <ul>
                                    <li>Unpaid Claims</li>
                                    <li>Low Paid Claims</li>
                                    <li>Denied Claims</li>
                                    <li>Rejected Claims</li>
                                    <li>Claims not on file</li>
                                </ul>
                            </ul>
                        </div>
                        <div className="sub_heading mb-3">Insurance aging</div>
                        <div className="para mb-3">Accounts receivable is a key factor in determining the cash flow and it requires expertise in optimizing A/R ratios. Industry statistics prove that large sums of money is lost each year due to inadequate management of Accounts receivables. As a client-centric organization, we offer stand-alone service for AR which is seamlessly merged into your existing capabilities. It requires a proactive and diligent approach to monitor the receivables and devise action plans to maximize the cash flow.</div>
                        <div className="para mb-2">Cash flow and revenue management are among the most critical factors in any professionally run practice. At Pacific, we can assist you in driving your business growth by effectively managing your cash and revenue flow, by reducing the accounts receivable days and improving profitability by increasing the collection ratio.</div>
                        <div className="para mb-3">PhymedSol’s has a large team of AR follow up professionals who can identify patient accounts that need to be followed up and take necessary action to collect unpaid claims and underpaid claims.</div>
                        <div className="normal_text">Our Services in Account Receivable include:</div>
                        <div className="normal_text ml-5 pl-5">
                            <div>- AR analysis</div>
                            <div>- Aging Analysis (Insurance & patient)</div>
                            <div>- Self-pay follow-up</div>
                            <div>- Insurance follow-up</div>
                            <div>- Self-pay follow-up</div>
                        </div>
                        <div className="normal_text">Denial Management Insights</div>
                        <div className="normal_text mt-3 mb-3">Insights and recommendations provided on improving existing processes, recommendations for documentation, claim management, and payer analytics</div>
                        <div className="heading text-center mb-4 mt-5" id="credit-balance" ref={CreditBalanceRef}>Credit Balance Resolution</div>
                        <div className="para my-3">Federal payers are regulated by law on overpayments; any delay in reimbursement will certainly attract fines and multiple instances of unresolved credit balances or misstating revenues could impact your facility’s financial compliance Supplied analytics and trending allowing you the necessary evidence to proactively resolve future overpayment occurrences. Inappropriate credits, duplicate payments, and inaccurate patient estimation.We can provide the necessary support to handle the high volumes allowing your staff to focus on cash priorities</div>
                        <div className="sub_heading mb-3">How do you benefit?</div>
                        <div className="para my-3">Reduction in posting errors, by providing insights to improve payment posting efficiency. Timely refund of any confirmed overpayment, adhering to federal guidelines. Reduction in overpayment refunds by through validation of contracts and reimbursements receive. Revenue protection (Overpayment validation); Accelerated and accurate patient refunds</div>
                        <div className="bg-white additional_serbvice">
                            <div className="heading text-center mb-4 mt-5">Addintional services</div>
                            <div className="sub_heading mb-3">Patient access Services</div>
                            <div className="para mb-3">Enhance the patient experience while improving reimbursement, reducing costs, and increasing efficiencies.</div>
                            <div className="normal_text">
                                <ul>
                                    <li>Scheduling & Registration</li>
                                    <li>Insurance Eligibility & Benefits Verification</li>
                                    <li>Prior Authorizations</li>
                                    <li>Healthcare Call Center</li>
                                </ul>
                            </div>
                            <div className="sub_heading mb-3">Office Services</div>
                            <div className="normal_text">Optimize reimbursements, increase cash flow, and reduce bad debt write-offs.</div>
                            <div className="normal_text">
                                <ul>
                                    <li>Claims Management & Billing</li>
                                    <li>Payment Posting & Reconciliation</li>
                                    <li>A/R Management & Collections</li>
                                    <li>Denials & Appeals Management</li>
                                    <li>Underpayment Analysis & Recovery</li>
                                    <li>Data Analytics Platform via WhiteSpace Health</li>
                                </ul>
                            </div>
                            <div className="sub_heading mb-3">Coding Services</div>
                            <div className="normal_text">Optimize coding efficiencies, reduce rejections and denials, and increase timely reimbursement.</div>
                            <div className="normal_text">
                                <ul>
                                    <li>Medical Records Coding</li>
                                    <li>Chart Audit</li>
                                    <li>Charge Capture</li>
                                    <li>Clinical Documentation Improvement</li>
                                    <li>Registry Services</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="third_section_bg">
            </section>
            <Footer />
        </div>
    );
}

export default OurService;
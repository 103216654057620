import React from 'react';
import Footer from '../Footer';
import HomePageSubComponents from '../Home-sub-components/subComponent';
import ServiceComponent from './../Service';
import HomeOurService from './../Home-Our-service';
import Homecontact from '../Home-sub-components/home-contact';

import './home.css';


const Home = () => {
    return (
        <div id="home-page">
            <div> <HomePageSubComponents /></div>
           <div> <ServiceComponent /></div>
           <div className="mt-5"> <HomeOurService /> </div>
            <div><Homecontact/></div>
            <div><Footer /></div>
        </div>
    )
}

export default Home;
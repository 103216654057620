import React from 'react';
import PersonImg from './../../assets/images/about/about_pearson.png'

const AboutSecondPart = () => {
    const aboutData = {
            firstHeading: 'Practice Analysis',
            firstPara: 'Audit your practice weakness by accurate activity PMS reports, in order to streamline & strengthen the process.Do not hesitate to contact our experts, your interest is our desire. We look into your requirements and expertise in the process to make you understand the even & odds',
            secondHeading: 'Revenue Growth Vision',
            secondPara: 'We believe in Vision of our client practice for growth and revenue, our first step is to fully understand goals of our client organization and the challenges standing in your way.Our Solution to provider on end-to-end revenue cycletransition and deliver excellence services on the entire process, front, middle and back office.Our passion - We constantly look for innovative solutions to deliver an increased value & support to our client and patients in every aspect to exceed their expectations Our mission - customer satisfaction, coordination in working together with unselfishness for outcome to achieve business objectives and provide better care toproviders & patients.',
            thirdHeading: 'Why PhyMedSol ?',
            thirdPara: 'PhyMedSol - immerse your practice Revenue Growth Vision, We have experience with the largest and best healthcare providers.',
            fourthHeading: 'Our strategy:',
            fourthPara: [
                { 
                    para: ' Recruitment and retaining talent with relevant skills, knowledge, and experience',
                 },
                 {
                    para: 'Deploying the right people for the right project to deliver maximum value to our customers'
                 },
                 {
                     para: 'Huge investments in training, learning, and domain knowledge to enable employees to sharpen and refine skills'
                 },
            ]
    }

    return (
        <div id="secondPart">
            <section className="secondPart_bg_img">
                <div className="container">
                    <div className="row">
                    <div className="about_header text-white text-center mt-5 mb-5"></div>
                        <div className="col-lg-11 col-md-10 col-10 mx-auto secondpart_white_bg p-5">
                            <div className="row">
                                <div className="col-lg-6 col-md-12 col-12">
                                    <div className="content_heading mb-2">{aboutData?.firstHeading}</div>
                                    <div className="content_para mb-3">{aboutData?.firstPara}</div>
                                    <div className="content_heading mb-2">{aboutData?.secondHeading}</div>
                                    <div className="content_para">{aboutData?.secondPara}</div>
                                </div>
                                <div className="col-lg-6 col-md-12 col-12 text-center">
                                    <div className="mt-5">
                                        <img className="img-fluid img_border mb-3" src={PersonImg} />
                                    </div>
                                </div>
                            </div>
                        <div className="col-12">
                            <div className="content_heading mb-2">{aboutData?.thirdHeading}</div>
                            <div className="content_para mb-3">{aboutData?.thirdPara}</div>
                        </div>
                        <div className="col-12">
                            <div className="content_heading mb-2">{aboutData?.fourthHeading}</div>
                            <div>
                                <ul className="list_content">
                                    <li>{aboutData?.fourthPara[0].para}</li>
                                    <li>{aboutData?.fourthPara[1].para}</li>
                                    <li>{aboutData?.fourthPara[2].para}</li>
                                </ul>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default AboutSecondPart;
import React, { useState, useEffect } from 'react';

const HeaderCarousel = (props) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const carouselInfo = [
        {
            title: 'HIPAA Compaliance',
            description:'We implement steps in your process to enhance HIPAA compliance and help you effectively mitigate potential risks associated with non-compliance. Follow administrative, physical, and technical safeguards to ensure the security, integrity, and confidentiality of electronic PHI.',
        },
        {
            title: 'End-to-End RCM Services',
            description: 'We deliver solutions to avoid friction in Revenue Cycle Management which result to improved payment models and create financial impacts.',
        },
        {
            title: 'Medical Coding',
            description: 'Stay on top of regulatory changes in medical coding to ease the process and have AI-powered coding audits in place to reduce denial chances to near zero percent.',
        },
        {
            title: 'Claim submission & scrubbing',
            description: 'Deliver on time claims submission and scrubbing to accelerate payments faster and to enable practices to get more control over cash flow and speed up payments',
        },
        {
            title: 'Denial Management and A/R follow up',
            description: 'Our team uses 3 stage determining strategy (Initial evaluation, Analysis & Priorities, Collection) to manage all outstanding claims to ensure no claim “falls through the cracks”.',
        },
        {
            title: 'Improvise practice SOP',
            description: 'Our set of step-by-step instructions tailored for individual practices will achieve efficiency, quality output and uniformity of performance.',
        }
    ];

    useEffect(() => {
        const intervalId = setInterval(() => {
            const nextIndex = (currentIndex + 1) % carouselInfo.length;
            setCurrentIndex(nextIndex);
        }, 5000); // Change the interval duration as per your requirement (in milliseconds)

        return () => clearInterval(intervalId);
    }, [currentIndex, carouselInfo.length]);

    return (
        <div>
            <div id="carouselExampleDark" className="carousel carousel-dark slide">
                {props.page === "aboutPage" ? <div className="about-heading">{props.heading}</div>: <div>
                <div className="carousel-indicators">
                    {carouselInfo.map((item, index) => (
                        <button
                            key={index}
                            type="button"
                            data-bs-target="#carouselExampleDark"
                            data-bs-slide-to={index}
                            className={index === currentIndex ? 'active' : ''}
                            aria-label={`Slide ${index + 1}`}
                        ></button>
                    ))}
                </div>
                <div className="carousel-inner">
                    {carouselInfo.map((item, index) => (
                        <div
                            key={index}
                            className={`carousel-item ${index === currentIndex ? 'active' : ''}`}
                            data-bs-interval={index === currentIndex ? '5000' : ''}
                        >
                            <div className="carousel-caption d-block d-md-block">
                                <div className="header_text">{item.title}</div>
                                <div className="description">{item.description}</div>
                            </div>
                        </div>
                    ))}
                </div>
                <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExampleDark"
                    data-bs-slide="prev"
                    onClick={() => setCurrentIndex((currentIndex - 1 + carouselInfo.length) % carouselInfo.length)}
                >
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExampleDark"
                    data-bs-slide="next"
                    onClick={() => setCurrentIndex((currentIndex + 1) % carouselInfo.length)}
                >
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
                </div>}
            </div>
        </div>
    );
};

export default HeaderCarousel;

import React, { useState } from 'react';
import HeaderImageSection from './../Home-sub-components/subComponent';
import Footer from '../Footer';
import './at-glance.css';
import atGlanceBgImg from './../../assets/images/glance/atglance-header-Image.png';
import firstImage from './../../assets/images/glance/second.png';
import secondImage from './../../assets/images/glance/second_img.png';
// import ContactAddress from './Addess-page';

const AtGlance = () => {
    const [atGlancePage] = useState('atGlancePage');
    const [title] = useState('IMMERSE YOUR PRACTICE TO REVENUE GROWTH AND STRENGTHEN THE PRACTICE')

    const atGlanceData = {
        firstHeading: 'ELIGIBILITY CHECK',
        firstPara: 'We affirm each Patients Insurance coverage eligibility to streamline your technique, shorten account receivable days, and keep away from denials',
        secondHeading: 'MEDICAL CODING',
        secondPara: 'Effective medical coding requires comprehensive decision making, pin-point accuracy, and proven expertise. Our certified medical coding experts, code auditors and quality analysts guarantee coding accuracy. We Keep our coders up to date with trequently changing CPT, ICD-10, HCPeS books & softwares.',
        thirdHeading: 'CHARGE ENTRY',
        thirdPara: 'Get well-timed compensation as our targeted and error-loose charge access process ensures first-time accredited declaration submission.',
        fourHeading: 'CLAIMS SCRUBBING',
        fourPara: 'Our one of the best claim scrubbing ensures error-free and smooth claims are submitted for quicker reimbursement and minimum denials/rejections...'
    };

    const atGlanceDataTwo = {
        firstHeading: 'ELECTRONIC SUBMISSION',
        firstPara: 'Corrections and re-submissions are completed systematically and in a timely manner for any claims that are held to identify and resolve the issue as soon as possible.',
        secondHeading: 'PAYMENT POSTING',
        secondPara: 'Our Team follow payment posting essential steps such as posting, deposit functions and reconciling posting activities.',
        thirdHeading: 'FOLLOW-UP & APPEALS',
        thirdPara: 'For any denial, our A/R specialists will take rapid and powerful action to ensure well-timed reimbursements, preserving you up-to-date on appeals and reprocessing',
        fourHeading: 'PATIENT STATEMENTS',
        fourPara: 'After Insurance processed the claim and balance is the patient responsibility, a detailed Statement generated to send to patient showing the due.'

    }

    return(
        <div  id="atGlance-page">
            <HeaderImageSection
                page={atGlancePage}
                title = {title}
                backgroundImg = {atGlanceBgImg}
            />
            <div className="glance_heading text-center mt-5">Service at Glance</div>
            <div className="heading_border mb-5"></div>
            <div className="mt-5">
                <div className="container">
                    <div className="row">
                        <div className="col-11 mt-5 mx-auto">
                            <div className="row">
                            <div className="col-lg-6 col-md-12 col-12">
                            <div>
                                <div className='heading'>{atGlanceData.firstHeading}</div>
                                <div className="paragraph">{atGlanceData.firstPara}</div>
                                <div className='heading'>{atGlanceData.secondHeading}</div>
                                <div className="paragraph">{atGlanceData.secondPara}</div>
                                <div className='heading'>{atGlanceData.thirdHeading}</div>
                                <div className="paragraph">{atGlanceData.thirdPara}</div>
                                <div className='heading'>{atGlanceData.fourHeading}</div>
                                <div className="paragraph">{atGlanceData.fourPara}</div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <img className="img-fluid" src={firstImage} />
                        </div>
                            </div>
                        </div>
                    </div>


                    <div className="row mt-5 mb-5">
                        <div className="col-11 mt-5 mx-auto">
                            <div className="row">
                            <div className="col-lg-6 col-md-12 col-12">
                            <img className="img-fluid" src={secondImage} />
                        </div>
                            <div className="col-lg-6 col-md-12 col-12">
                            <div>
                                <div className='heading'>{atGlanceDataTwo.firstHeading}</div>
                                <div className="paragraph">{atGlanceDataTwo.firstPara}</div>
                                <div className='heading'>{atGlanceDataTwo.secondHeading}</div>
                                <div className="paragraph">{atGlanceDataTwo.secondPara}</div>
                                <div className='heading'>{atGlanceDataTwo.thirdHeading}</div>
                                <div className="paragraph">{atGlanceDataTwo.thirdPara}</div>
                                <div className='heading'>{atGlanceDataTwo.fourHeading}</div>
                                <div className="paragraph">{atGlanceDataTwo.fourPara}</div>
                            </div>
                        </div>
                        
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default AtGlance;
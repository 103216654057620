import React, { useState, useEffect } from 'react';
import HeaderImage from './../../assets/images/hedder.png';

const HeaderImageSection = (props) => {
    const [description, setDescription] = useState('IMMERSE YOUR PRACTICE TO REVENUE GROWTH AND STRENGTHEN THE PRACTICE');
    const [bgImage, setBgImg] = useState(HeaderImage);
    // const [imgText, setImgText] = useState(bgImgText);
    
    useEffect(() => {
        if (props.page === 'contactPage') {
            setDescription(props.title);
            setBgImg(props.backgroundImg);
        } else if (props.page === 'aboutPage') {
            setDescription(props.title);
            setBgImg(props.backgroundImg);
        } else if (props.page === 'atGlancepage') {
            setDescription(props.title);
            setBgImg(props.backgroundImg);
        } else if (props.page === 'ourServicePage') {
            setDescription(props.title);
            setBgImg(props.backgroundImg);
        }

    }, [props]);
    console.log(props, 'props 1')
    const mainDivClassName = determineMainDivClassName(props.page);
    const textDivClassName = determineTextDivClassName(props.page);

    function determineMainDivClassName(page) {
        switch (page) {
            case 'contactPage':
                return 'contact_header_bg_img';
            case 'aboutPage':
                return 'about_header_bg_img';
            case 'atGlancePage':
                return 'atglance_header_bg_img';
            case 'ourServicePage':
                return 'ourservice_header_bg_img';
            default:
                return 'header_bg_img';
        }
    }


    function determineTextDivClassName(page) {
        switch (page) {
            case 'contactPage':
                return 'contact_header_bg_img_text';
            // case 'aboutPage':
            //     return 'about_header_bg_img_text';
            default:
                return 'header_bg_img_text';
        }
    }

    return (
        <div>
            <div className={mainDivClassName}>
                <div className={textDivClassName}>{description}</div>
            </div>
        </div>
    )
}
export default HeaderImageSection;
import React, { useState } from 'react';
import './contact-address.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faEnvelope, faMobile } from '@fortawesome/free-solid-svg-icons';



const ContactAddress = () => {

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });
    const [errors, setErrors] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });

    const [successMsg, setSuccessMsg] = useState('');

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };


    const handleSubmit = () => {
        const newErrors = {};
        let hasErrors = false;

        // Validate form fields
        if (!formData.name) {
            newErrors.name = 'Please enter your name.';
            hasErrors = true;
        }
        if (!formData.email) {
            newErrors.email = 'Please enter your email address.';
            hasErrors = true;
        } else {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(formData.email)) {
                newErrors.email = 'Please enter a valid email address.';
                hasErrors = true;
            }
        }
        if (!formData.subject) {
            newErrors.subject = 'Please enter the subject.';
            hasErrors = true;
        }
        if (!formData.message) {
            newErrors.message = 'Please enter your message.';
            hasErrors = true;
        }
        setErrors(newErrors);
        if (hasErrors) {
            return;
        }
        setSuccessMsg('You have successfully submitted.We will get back to you soon.')
        
        setTimeout(() => {
            setSuccessMsg('');
        }, 4000);
        // Your logic to handle the form submission
        console.log('Form submitted:', formData);
        setFormData({
            name: '',
            email: '',
            subject: '',
            message: ''
        });
    };

    return (
        <section id="contact-address-page">
            <div className="container">
                <div className="row">
                    <div className="col-lg-11 col-xl-11 col-12 mx-auto">
                        <div className="contact_bg">
                            <div className="header_text">CONTACT US</div>
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 cool-12">
                                    <div className="message_text">Leave a Message</div>
                                    <div className="row">
                                        <div className="col-lg-6 col-xl- 6 col-12 mt-3">
                                            <input
                                                placeholder="Your name"
                                                type="text"
                                                className="form-control box_shadow_inpt"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleInputChange}
                                            />
                                            {errors.name && <div className="error-message">{errors.name}</div>}
                                        </div>
                                        <div className="col-lg-6 col-xl-6 col-12 mt-3">
                                            <input
                                                placeholder="Your mail"
                                                type="text"
                                                className="form-control box_shadow_inpt"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleInputChange}
                                            />
                                            {errors.email && <div className="error-message">{errors.email}</div>}
                                        </div>
                                        <div className="col-12 mt-4">
                                            <div>
                                                <input
                                                    type="text"
                                                    className="form-control box_shadow_inpt"
                                                    placeholder="Subject"
                                                    name="subject"
                                                    value={formData.subject}
                                                    onChange={handleInputChange}
                                                />
                                                {errors.subject && <div className="error-message">{errors.subject}</div>}
                                            </div>
                                        </div>
                                        <div className="col-12 mt-4">
                                            <div>
                                                <textarea
                                                    type="text"
                                                    className="form-control box_shadow_inpt"
                                                    rows="6"
                                                    placeholder="Message"
                                                    name="message"
                                                    value={formData.message}
                                                    onChange={handleInputChange}
                                                ></textarea>
                                                {errors.message && <div className="error-message">{errors.message}</div>}
                                            </div>
                                            {successMsg && <div className="success-message my-2">{successMsg}</div>}
                                            <div>
                                                <button className="btn btn-primary mt-4" onClick={handleSubmit}>Send Message</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-12">

                                    <div className="contact_us_text mt-5 pt-4 text-center">
                                        <div> Don't Hesitate to call us anytime</div>
                                        <div>+1 (689) 224-4176</div>
                                    </div>
                                    <div className="address_container">
                                        <div className="head_office_line">
                                            <div className="office_text">Head Office</div>
                                            <div className="p-r-10 address_icon">
                                                <div className="mr-2 icon_right_2">
                                                    <FontAwesomeIcon className="first_section_icon pr-3" icon={faMapMarkerAlt} />
                                                </div>
                                                <div className="pr-4"> 274 Merry Brook Circle, Sanford, FL 32771, USA.</div>
                                            </div>
                                            <div className="pr-4">  <FontAwesomeIcon className="first_section_icon pr-3" icon={faMobile} /> <a href='tel:+1(689) 224-4176' className="number">+1 (689) 224-4176</a> </div>
                                            <div className="address_icon">
                                                <div className="mr-2 icon_right_2">
                                                    <FontAwesomeIcon className="first_section_icon pr-3" icon={faEnvelope} />
                                                </div>
                                                <div className="pr-4"> <a className="number" href=" mailto:info@phymedsol.com">info@phymedsol.com</a></div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="office_text branch_office">Branch Office</div>
                                            <div className="p-r-10 address_icon">
                                                <div className="mr-2 icon_right_2">
                                                    <FontAwesomeIcon className="first_section_icon pr-3" icon={faMapMarkerAlt} />
                                                </div>
                                                <div className="pr-4"> Nancy Building,
                                                    Main road, HitechCity, Hyderabad, TS </div> 
                                            </div>
                                            <div className="pr-4">  <FontAwesomeIcon className="first_section_icon pr-3" icon={faMobile} /> <a href='tel:+919963611612' className="number">+91 9963611612</a> </div>
                                            <div className="address_icon">
                                                <div className="mr-2 icon_right_2">
                                                    <FontAwesomeIcon className="first_section_icon pr-3" icon={faEnvelope} />
                                                </div>
                                                <div className="pr-4"> <a className="number" href=" mailto:info@phymedsol.com">info@phymedsol.com</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactAddress;
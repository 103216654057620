import React, { useState } from 'react';
import HeaderImageSection from './../Home-sub-components/subComponent';
import Footer from '../Footer';
import './index.css';
import aboutBgImg from './../../assets/images/about/about-header-Img.png';
import AboutSecondPart from './second-part';

const About = () => {
    const [aboutPage] = useState('aboutPage');
    const [heading] = useState('We are a U.S. based medical coding and billing company.We provide billing services both from onsite and offshore at a competitive & affordable rates. We can help you with...');
    const [title] = useState('IMMERSE YOUR PRACTICE TO REVENUE GROWTH AND STRENGTHEN THE PRACTICE');
    

    return(
        <div  id="about-page">
            <HeaderImageSection
                page={aboutPage}
                title = {title}
                backgroundImg = {aboutBgImg}
                heading = {heading}
            />
            <div className="first_about_bg"></div>
                <AboutSecondPart />
            <div className="third_about_pg"></div>
            <Footer/>
        </div>
    )
}

export default About;